import { IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonProgressBar, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import ItemOperacion from '../../components/ItemOperacion';
import DataContext, { Operacion } from '../../data/data-context';
import { useHistory } from 'react-router-dom';
import { cellularOutline, logOut, person } from 'ionicons/icons';
import './AllOperaciones.css';

const AllOperaciones: React.FC = () => {

    const history = useHistory();

    const dataContext = useContext(DataContext);
    const [spinnerPage, setSpinnerPage] = useState<boolean>(true);


    // useIonViewDidEnter(() => {
    //     console.log(" Holaaa !!");
    //     dataContext.obtenerOperaciones();
    // });

    const agregarValidarLogeo = () => {
        if (!!!dataContext.getToken()) {
            history.replace('/main');
        }
    }

    const regresarLogin = () => {
        history.replace('/main');
    }



    const cargandoPaginaInicial = () => {
        window.setTimeout(() => {
            setSpinnerPage(false);
        }, 350)
    }


    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador
        agregarValidarLogeo();
        dataContext.obtenerOperaciones();
        cargandoPaginaInicial();

    }, []);

    return (<IonPage className="page-operaciones">
        {/* 

        <IonFab vertical="top" horizontal="start" slot="fixed">
            <IonChip>
                <IonIcon icon={person} color="primary" />
                <IonLabel className="text-avatar">{dataContext.trabajador.apellidos_nombres}</IonLabel>
            </IonChip>
        </IonFab> */}


        <IonHeader className="content-titulo-pagina background-image">
            <IonChip>
                <IonIcon icon={person} color="primary" />
                <IonLabel className="text-avatar">{dataContext.getApellidosNombres()}</IonLabel>
            </IonChip>

            <IonChip className="salir" outline color="danger" onClick={() => dataContext.salirSistema()}>
                <IonIcon icon={logOut} color="danger" />
                <IonLabel className="text-avatar">SALIR</IonLabel>
            </IonChip>

            <IonToolbar>

                <IonTitle className="ion-text-center text-titulo-pagina">
                    Operaciones
                </IonTitle>
            </IonToolbar>
        </IonHeader>

        {spinnerPage ? <IonContent className="ion-text-center background-image">
            <IonProgressBar color="primary" value={0.5} type="indeterminate"></IonProgressBar>
            <IonIcon icon={cellularOutline} size="large"></IonIcon>
        </IonContent> :


            <IonContent className="background-image">
                {/* <IonFab vertical="bottom" horizontal="end" edge slot="fixed" className="buttom-back">
                    <IonFabButton onClick={() => regresarLogin()}>
                        <IonIcon icon={caretBack} />
                    </IonFabButton>
                </IonFab> */}
                <IonRow>
                    <IonCol size="12" size-md="2">
                    </IonCol>
                    <IonCol size="12" size-md="8">
                        <IonGrid>
                            <IonRow>
                                {
                                    dataContext.operaciones.map(item => (
                                        <ItemOperacion key={item.id} operacion={item as Operacion} />
                                    ))
                                }
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                    <IonCol size="12" size-md="2">
                    </IonCol>
                </IonRow>
            </IonContent>


        }
    </IonPage>)
}

export default AllOperaciones;