import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Main from './pages/Main/Main';
import AllOperaciones from './pages/AllOperaciones/AllOperaciones';
import DataContextProvider from './data/DataContextProvider';
import AllVotaciones from './pages/AllVotaciones/AllVotaciones';
import AllQuintaCategoria from './pages/AllQuintaCategoria/AllQuintaCategoria';
import AllVotacionResultados from './pages/AllVotacionResultados/AllVotacionResultados';
import { RouteComponentProps } from "react-router-dom";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>

      <DataContextProvider>

        <IonRouterOutlet>
          {/* P'agina principal para validar datos del usuario */}
          <Route exact path="/main">
            <Main />
          </Route>
          <Route exact path="/all_operaciones">
            <AllOperaciones />
          </Route>
          <Route exact path="/votacion/:id" component={AllVotaciones}>
          </Route>
          <Route exact path="/votacion_resultados/:id">
            <AllVotacionResultados />
          </Route>
          <Route exact path="/quinta_categoria/:id">
            <AllQuintaCategoria />
          </Route>          
          <Redirect to='/main' />
        </IonRouterOutlet>
      </ DataContextProvider>
    </IonReactRouter>
  </IonApp>
);

export default App;
