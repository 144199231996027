import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol } from "@ionic/react";

import React, { useContext, useEffect, useState } from "react";
import { Operacion } from "../data/data-context";
import './ItemOperacion.css';
import { useHistory } from 'react-router-dom';
import DataContext from '../data/data-context';

interface CompleteModalActivityProps {
    operacion: Operacion;
}

const ItemOperacion: React.FC<CompleteModalActivityProps> = (props) => {

    const history = useHistory();
    const [estado, setEstado] = useState<boolean>(false);
    const [mensajeEstado, setMensajeEstado] = useState<string>(" Actividad culminada");
    const dataContext = useContext(DataContext);


    const elegirOperacion = (url: string) => {
        history.replace(url + "/" + props.operacion.id);
    }

    const validarOperacion = () => {
        var fecha_actual = new Date();
        if (props.operacion.fecha_fin) {
            var fecha_inicio = new Date(props.operacion.fecha_inicio);
            var fecha_fin = new Date(props.operacion.fecha_fin);

            if (fecha_actual < fecha_inicio && fecha_actual < fecha_fin) {
                setEstado(false);
                setMensajeEstado(" Inicio: " + fecha_inicio.toLocaleString('es-PE'))
            }

            if (fecha_actual >= fecha_inicio && fecha_actual <= fecha_fin) {
                setEstado(true);
            }

        }
    }


    useEffect(() => {
        validarOperacion();
    }, []);


    return (
        <IonCol size="12" size-md="6">
            <IonCard className="card">
                <img src={props.operacion.imagen} alt="Operacion" className="img-operacion" />
                <IonCardHeader>
                    {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                    <IonCardTitle className="titulo-operacion">{props.operacion.nombre}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent className="contend-item">
                    {props.operacion.descripcion}
                    <div className="ion-margin-top">
                        {dataContext.getIsAdmin() === "true" ? <IonButton expand='block' color="warning" onClick={() => elegirOperacion(props.operacion.url)}>
                            Ver </IonButton> : estado ? <IonButton expand='block' color="warning" onClick={() => elegirOperacion(props.operacion.url)} >
                                {props.operacion.texto_enlace}
                            </IonButton> : <IonBadge color="danger">{mensajeEstado}</IonBadge>}
                    </div>

                </IonCardContent>
            </IonCard>
        </IonCol>

    );
};

export default ItemOperacion;
