import { IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { caretBack } from "ionicons/icons";
import { useHistory } from 'react-router-dom';
import '../Main/Main.css';

const AllQuintaCategoria: React.FC = () => {
    const history = useHistory();
    const regresarOperaciones = () => {
        history.replace('/all_operaciones');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Constancias de Quinta Categoría
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonFab vertical="bottom" horizontal="start" edge slot="fixed" className="buttom-back">
                    <IonFabButton onClick={() => regresarOperaciones()}>
                        <IonIcon icon={caretBack} />
                    </IonFabButton>
                </IonFab>
                <h1> Página en mantenimiento... </h1>
            </IonContent>
        </IonPage>
    )
}

export default AllQuintaCategoria;