import { IonCard, IonCardContent, IonCardTitle, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonProgressBar, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { caretBack, cellularOutline, logOut, person } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DataContext from "../../data/data-context";
import "./AllVotacionResultados.css"

const AllVotacionResultados: React.FC = () => {

    const history = useHistory();

    const dataContext = useContext(DataContext);
    const [spinnerPage, setSpinnerPage] = useState<boolean>(true);


    const agregarValidarLogeo = () => {
        if (!!!dataContext.getToken()) {
            history.replace('/main');
        }
    }

    const regresarOperaciones = () => {
        history.replace('/all_operaciones');
    }


    const cargandoPaginaInicial = () => {
        window.setTimeout(() => {
            setSpinnerPage(false);
        }, 800)
    }


    useEffect(() => {
        dataContext.obtenerVotacionResultado();
        agregarValidarLogeo();
        cargandoPaginaInicial();
    }, []);


    return (
        <div>
            <IonPage className="page-resultados">

                <IonHeader className="content-titulo-pagina background-image">
                    <IonChip>
                        <IonIcon icon={person} color="primary" />
                        <IonLabel className="text-avatar">{dataContext.getApellidosNombres()}</IonLabel>
                    </IonChip>
                    <IonChip className="salir" outline color="danger" onClick={() => dataContext.salirSistema()}>
                        <IonIcon icon={logOut} color="danger" />
                        <IonLabel className="text-avatar">SALIR</IonLabel>
                    </IonChip>
                    <IonToolbar>
                        <IonTitle className="ion-text-center text-titulo-pagina">
                            Resultados
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                {spinnerPage ? <IonContent className="ion-text-center background-image">
                    <IonProgressBar color="primary" value={0.5} type="indeterminate"></IonProgressBar>
                    <IonIcon icon={cellularOutline} size="large"></IonIcon>
                </IonContent> :
                    <IonContent className="background-image">
                        <IonFab vertical="bottom" horizontal="end" edge slot="fixed" className="buttom-back">
                            <IonFabButton onClick={() => regresarOperaciones()} >
                                <IonIcon icon={caretBack} />
                            </IonFabButton>
                        </IonFab>


                            <IonRow>
                                <IonCol size="12" size-md="2">
                                </IonCol>
                                <IonCol size="12" size-md="8">
                                    <IonGrid>
                                        <IonRow >
                                            <IonCol size="12" size-md="3">
                                            </IonCol>
                                            <IonCol size="12" size-md="6">
                                                <IonCard className="background-image">
                                                    <IonCardTitle className="ion-text-center">
                                                        <p className="titulo-resultado" >Conteo de votos</p>
                                                    </IonCardTitle>
                                                    <IonCardContent >

                                                        <IonList>
                                                            {
                                                                dataContext.votacionResultado.listas.map((item, index) => {
                                                                    return <div key={index} >
                                                                        <IonItem>
                                                                            <IonNote slot="start" className="resultado-item-foto"><img src={dataContext.url_api + item.foto} width="35" /> </IonNote>
                                                                            <p className="texto-resultado"> <strong > <IonText color="primary">{index + 1}.-</IonText> </strong> {item.nombre}  </p>
                                                                            <IonNote slot="end" color="primary"><strong>{item.votos_count}</strong>  Votos</IonNote>
                                                                        </IonItem>
                                                                    </div>

                                                                })
                                                            }
                                                            {dataContext.votacionResultado ?
                                                               <>
                                                                <div key={3} >
                                                                    <IonItem>
                                                                        <IonNote slot="start" className="resultado-item-foto"></IonNote>
                                                                        <p className="texto-resultado"> VOTO BLANCO  </p>
                                                                        <IonNote slot="end" color="primary"><strong>{dataContext.votacionResultado.votos_blanco}</strong>  Votos</IonNote>
                                                                    </IonItem>
                                                                
                                                                    <div key={4} >
                                                                    <IonItem>
                                                                        <IonNote slot="start" className="resultado-item-foto"></IonNote>
                                                                        <p className="texto-resultado"> VOTO NULO  </p>
                                                                        <IonNote slot="end" color="primary"><strong>{dataContext.votacionResultado.votos_nulo}</strong>  Votos</IonNote>
                                                                    </IonItem>
                                                                </div>
                                                                </div>

                                                               </>
                                                                : null}

                                                        </IonList>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                            <IonCol size="12" size-md="3">
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>

                                </IonCol>
                                <IonCol size="12" size-md="2">
                                </IonCol>
                            </IonRow>
 

                    </IonContent>
                }
            </IonPage>
        </div>)
}

export default AllVotacionResultados;