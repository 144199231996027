import { IonButton, IonButtons, IonCard, IonCardContent, IonCardTitle, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonProgressBar, IonReorderGroup, IonRow, IonTitle, IonToast, IonToolbar, useIonActionSheet } from "@ionic/react"
import { caretBack, cellularOutline, close, logOut, person, star, checkboxOutline, trash, heart } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import DataContext, { Lista, Votacion } from "../../data/data-context";
import './AllVotaciones.css';
import { RouteComponentProps } from "react-router-dom";
interface AllVotacionesProps
    extends RouteComponentProps<{
        id: string;
    }> { }

const AllVotaciones: React.FC<AllVotacionesProps> = ({ match }) => {


    type toastObject = {
        text: string;
        type: string;
    };

    const [toast, setToast] = useState<toastObject | undefined>(undefined);
    const dataContext = useContext(DataContext);
    const [votacion, setVotacion] = useState<Votacion>();

    const [spinner, setSpinner] = useState<boolean>(false);
    const [spinnerPage, setSpinnerPage] = useState<boolean>(true);

    const [modalVoto, setModalVoto] = useState<Boolean>(false);
    const [listaData, setListaData] = useState<Lista[]>([]);

    const [listaObject, setListaObject] = useState<Lista>();
    const [present, dismiss] = useIonActionSheet();



    const history = useHistory();


    const openModalVoto = (lista: Lista | undefined, tipo: number) => {
        setModalVoto(true);
        setListaObject(lista);
        if (lista) {
            setVotacion({ ...votacion, lista: lista?.id, tipo: tipo });
        } else {
            setVotacion({ ...votacion, lista: null, tipo: tipo });
        }

    };


    const agregarTrabajador = () => {
        const votacion_new = { ...votacion } as Votacion;
        votacion_new.id = null;
        votacion_new.tipo = 1;
        votacion_new.periodo = 1;
        votacion_new.lista = null;
        votacion_new.empleado = parseInt(dataContext.getId() || "");
        // votacion_new.trabajador_object = dataContext.trabajador();
        setVotacion(votacion_new);
    }

    const agregarValidarLogeo = () => {
        if (!!!dataContext.getToken()) {
            history.replace('/main');
        }
    }


    const getListas = async () => {
        await fetch(dataContext.url_api + '/api/lista', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + dataContext.getToken()
            },
        })
            .then(response => response.json())
            .catch(error => {
                const toastInstance: toastObject = {
                    text: "Ocurrio un error del servidor.",
                    type: "danger",
                }
                setToast(toastInstance);
            })
            .then(response => {

                if (response === undefined || response === null) {
                    history.replace('/main');
                } else {
                    if (response.detail === "Token inválido.") {
                        history.replace('/main');
                    } else {
                        setListaData(response.results)
                        console.log(listaData)
                    }
                }
            });
    }



    const getVotacion = async () => {
        await fetch(dataContext.url_api + '/api/votacion/?empleado_dni=' + dataContext.getDni(), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + dataContext.getToken()
            },
        })
            .then(response => response.json())
            .catch(error => {
                const toastInstance: toastObject = {
                    text: "Ocurrio un error del servidor.",
                    type: "danger",
                }
                setToast(toastInstance);
            })
            .then(response => {
                if (response === undefined || response === null) {
                    history.replace('/main');
                } else {

                    if (response.detail === "Token inválido.") {
                        history.replace('/main');
                    } else {
                        if (response.results.length === 0) {
                            agregarTrabajador();
                        } else {
                            const votacion_new = { ...response.results[0] } as Votacion;
                            setVotacion(votacion_new);
                            const toastInstance: toastObject = {
                                text: "Usted ya realizó su voto. Gracias",
                                type: "primary",
                            }
                            setToast(toastInstance);
                        }
                    }
                }
            });
    }


    const postVotacion = async () => {
        await fetch(dataContext.url_api + '/api/votacion/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + dataContext.getToken()
            },
            // body: JSON.stringify({ a: 1, b: 'Textual content' })
            body: JSON.stringify(votacion)
        }).then(response => response.json())
            .catch(error => {
                const toastInstance: toastObject = {
                    text: "Ocurrio un error",
                    type: "danger",
                }
                setToast(toastInstance);
                setSpinner(false)
            })
            .then(response => {
                if (response.id) {
                    setSpinner(false)
                    const toastInstance: toastObject = {
                        text: "Voto enviado correctamente.",
                        type: "success",
                    }
                    setToast(toastInstance);
                    setModalVoto(false);
                    setVotacion(response);
                } else {
                    setSpinner(false)
                    const toastInstance: toastObject = {
                        text: response.non_field_errors,
                        type: "danger",
                    }
                    setToast(toastInstance);
                    setModalVoto(false);
                }
            });
    }


    const enviarVotacion = () => {
        setSpinner(true)

        if (votacion) {
            window.setTimeout(() => {
                postVotacion();
            }, 300)
        } else {
            const toastInstance: toastObject = {
                text: "Voto NO ENVIADO, Debe elegir en las dos opciones o Votar en blanco.",
                type: "danger",
            }
            setToast(toastInstance);
            setSpinner(false)
        }
    };



    const regresarOperaciones = () => {
        history.replace('/all_operaciones');
    }

    const cleanToast = () => {
        const toastInstance: toastObject = {
            text: "",
            type: "medium",
        }
        setToast(toastInstance);
    }

    const cargandoPaginaInicial = () => {
        window.setTimeout(() => {
            setSpinnerPage(false);
        }, 350)
    }


    useEffect(() => {

        getVotacion();
        agregarValidarLogeo();
        cargandoPaginaInicial();
        getListas();

    }, []);


    return (

        <div>

            <IonModal
                isOpen={modalVoto as boolean}
                swipeToClose={true}
                backdropDismiss={false}
            >
                <IonHeader translucent>
                    <IonToolbar>
                        <IonTitle>Emitir mi voto</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setModalVoto(false)} color="danger">
                                <IonIcon icon={close}> </IonIcon> Cancelar
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {spinner ?
                        <IonContent className="ion-text-center background-image">
                            <IonProgressBar color="primary" value={0.5} type="indeterminate"></IonProgressBar>
                            Enviando voto.
                        </IonContent>
                        : <IonCard>

                            <IonCardContent>
                                <IonButton
                                    expand="block"
                                    onClick={() =>
                                        present({
                                            buttons: [{
                                                text: 'Cancelar',
                                                icon: close,
                                                role: 'cancel',
                                                handler: () => {
                                                    console.log('Cancel clicked');
                                                },
                                            },
                                            {
                                                text: 'Confirmar mi voto',
                                                icon: heart,
                                                handler: () => {
                                                    console.log('Favorite clicked');
                                                    enviarVotacion();
                                                },
                                            }],
                                            header: 'Confirmar voto',
                                        })
                                    }
                                >
                                    VOTAR   <IonIcon icon={checkboxOutline}  />
                                </IonButton>
                            </IonCardContent>
                            {listaObject ?
                                <IonCardContent>


                                    <h1 className="ion-text-center">{listaObject?.nombre} </h1>
                                    <p className="textInfo ">Integrantes</p>

                                    {
                                        listaObject?.candidatos.map(item => (
                                            <IonCard className="card-candidato" key={item.id}>
                                                <IonCardContent className="content-candidato">
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size="2"> <img src={item.foto} width="40" /> </IonCol>
                                                            <IonCol size="6"> <p className="nombre-candidato">{item.nombre_completo}</p>
                                                                <p ><strong> {item.cargo_cand} </strong></p> </IonCol>
                                                            {/* <IonCol size="4"> <IonButton color="success" size="small" expand="block" onClick={() => elegirCandidato(item)}>Elegir </IonButton> </IonCol> */}
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCardContent>
                                            </IonCard>
                                        ))
                                    }
                                </IonCardContent>
                                :
                                <>
                                    {votacion?.tipo === 1 ?
                                        <h1 className="ion-text-center"> VOTO BLANCO <br/>
                                        <small> (Voto se le asignara al ganador)</small></h1>
                                        : <h1 className="ion-text-center"> VOTO NULO <br/>
                                        <small> (Voto no será contabilizado para ninguna lista)</small> </h1>}

                                </>
                            }
                        </IonCard>}
                </IonContent>
            </IonModal>

            <IonPage className="page-votaciones">

                <IonHeader className="content-titulo-pagina background-image">
                    <IonChip>
                        <IonIcon icon={person} color="primary" />
                        <IonLabel className="text-avatar">{dataContext.getApellidosNombres()}</IonLabel>
                    </IonChip>
                    <IonChip className="salir" outline color="danger" onClick={() => dataContext.salirSistema()}>
                        <IonIcon icon={logOut} color="danger" />
                        <IonLabel className="text-avatar">SALIR</IonLabel>
                    </IonChip>
                    <IonToolbar className="ion-text-center">
                        <IonTitle className="ion-text-center text-titulo-pagina">
                            Elecciones 2022
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                {spinnerPage ? <IonContent className="ion-text-center background-image">
                    <IonProgressBar color="primary" value={0.5} type="indeterminate"></IonProgressBar>
                    <IonIcon icon={cellularOutline} size="large"></IonIcon>
                </IonContent> :
                    <IonContent className="background-image">

                        <IonToast isOpen={!!toast?.text}
                            message={toast?.text}
                            duration={2000}
                            color={toast?.type}
                            onDidDismiss={() => cleanToast()}
                            position="middle"
                        />
                        <IonFab vertical="bottom" horizontal="end" edge slot="fixed" className="buttom-back">
                            <IonFabButton onClick={() => regresarOperaciones()}>
                                <IonIcon icon={caretBack} />
                            </IonFabButton>
                        </IonFab>

                        <IonRow>
                            <IonCol size="12" size-md="4">
                            </IonCol>
                            <IonCol size="12" size-md="4">
                                {votacion?.id ?
                                    <>
                                        <IonCardTitle className="ion-text-center">
                                            <p>Usted ya emitió su voto, gracias.</p>
                                        </IonCardTitle>
                                        {votacion.tipo === 0 ?
                                            <IonReorderGroup disabled={false}>
                                                <IonItem >
                                                    <IonLabel className="nombreLista">
                                                        {votacion.lista_object?.nombre}
                                                    </IonLabel>
                                                    <IonLabel slot="end" className="fotoLista">
                                                        <img src={votacion.lista_object?.foto} width="60" alt="foto" />
                                                    </IonLabel>
                                                </IonItem>
                                            </IonReorderGroup>
                                            : null}

                                        {votacion.tipo === 1 ?
                                            <IonItem className="ion-text-center">
                                                <IonLabel className="nombreListaVoto">
                                                    VOTO BLANCO
                                                    <br/>
                                                        <small> (Voto se le asignara al ganador)</small>
                                                </IonLabel>
                                            </IonItem>
                                            : null}


                                        {votacion.tipo === 2 ?
                                            <IonItem className="ion-text-center">
                                                <IonLabel className="nombreListaVoto">
                                                    VOTO NULO <br/>
                                                        <small> (Voto no será contabilizado para ninguna lista)</small>
                                                </IonLabel>
                                            </IonItem>
                                            : null}


                                    </>
                                    :
                                    <IonGrid >
                                        <IonCardTitle className="ion-text-center">
                                            <p>Elija una lista o voto en blanco/nulo</p>
                                        </IonCardTitle>
                                        <IonList>
                                            <IonReorderGroup disabled={false}>
                                                {listaData ?
                                                    listaData.map(item => (
                                                        <IonItem onClick={() => openModalVoto(item, 0)}>
                                                            <IonLabel className="nombreLista">
                                                                {item?.nombre}
                                                            </IonLabel>
                                                            <IonLabel slot="end" className="fotoLista">
                                                                <img src={item?.foto} width="60" alt="foto" />
                                                            </IonLabel>
                                                        </IonItem>
                                                    )) : null
                                                }
                                                <IonItem onClick={() => openModalVoto(undefined, 1)}>
                                                    <IonLabel className="nombreListaVoto">
                                                        VOTO BLANCO <br/>
                                                        <small> (Voto se le asignara al ganador)</small>
                                                    </IonLabel>
                                                </IonItem>
                                                <IonItem onClick={() => openModalVoto(undefined, 2)}>
                                                    <IonLabel className="nombreListaVoto">
                                                        VOTO NULO<br/>
                                                        <small> (Voto no será contabilizado para ninguna lista)</small>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonReorderGroup>
                                        </IonList>
                                    </IonGrid>}

                            </IonCol>
                            <IonCol size="12" size-md="4">
                            </IonCol>
                        </IonRow>



                    </IonContent>
                }


            </IonPage>
        </div>

    )
}

export default AllVotaciones;