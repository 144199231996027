import React from 'react';

export interface Bases {
    id: number;
    nombre: string;
    archivo: string;
}

export interface MesaAyuda {
    id: number;
    mensaje: string;
    numero: string;
    numero_1: string;
    imagen: string;
}



export interface Operacion {
    id: number;
    nombre: string;
    imagen: string;
    descripcion: string;
    orden: number;
    url: string;
    texto_enlace: string;
    estado: number;
    fecha_inicio: string;
    fecha_fin: string;
}

export interface Trabajador {
    id: number;
    dni: string;
    nombre_completo: string;
    cargo: string;
    token: string;
    is_admin: Boolean;
}

export interface Votacion {
    id?: number|null;
    tipo?: number;
    periodo?: number;
    lista_object?:Lista;
    empleado_object?: Trabajador;
    lista?: number|null;
    empleado?: number
}

export interface CabeceraResultado {
    id: number,
    documento: string,
    descripcion: string,
    estado: boolean,
    periodo: number
}

export interface Candidato {
    id: number;
    lista: string;
    dni: string;
    nombre_completo: string;
    cargo: string;
    establecimiento: string;
    foto: string;
    cargo_cand: string;
    tipo_cand: string;
}


export interface Lista {
    id: number,
    periodo: string,
    orden: number,
    nombre: string,
    descripcion: string,
    foto: string,
    estado: boolean,
    candidatos: Candidato[],
    votos_count?: number
}



export interface VotacionResultado{
    listas: Lista[],
    votos_blanco: 0,
    votos_nulo: 0
}


export interface DataContextModel {
    operaciones: Operacion[];
    obtenerOperaciones: () => void;
    agregarTrabajador: (id: number,dni: string, apellidos_nombres: string, cargo: string, token: string, is_admin: Boolean) => void;
    url_api: string;
    votacionResultado: VotacionResultado;
    obtenerVotacionResultado: () => void;
    mesaAyuda: MesaAyuda;
    obtenerMesaAyuda: () => void;
    salirSistema: () => void;
    getToken: () => string | null;
    getApellidosNombres: () => string | null;
    getDni: () => string | null;
    getIsAdmin: () => string | null;
    getId: () => string | null;
    obtenerBases: () => void;
    bases: Bases;
}

const DataContext = React.createContext<DataContextModel>({
    operaciones: [],
    obtenerOperaciones: () => { },
    agregarTrabajador: () => { },
    url_api: "",
    votacionResultado: <VotacionResultado>{},
    obtenerVotacionResultado: () => { },
    mesaAyuda: <MesaAyuda>{},
    obtenerMesaAyuda: () => { },
    salirSistema: () => { },
    getToken: () => "",
    getApellidosNombres: () => "",
    getDni: () => "",
    getIsAdmin: () => "",
    getId: () => "",
    obtenerBases: () => "",
    bases: <Bases>{},
});

export default DataContext;